import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CurveHero from "../components/CurveHero"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const query = graphql`
  query {
    wpPage(slug: {eq: "future"}) {
      timeline {
        timeline {
          timelineContent
          timelineHeading
          timelineImage {
      slug
      uri
      srcSet
      sourceUrl
      localFile {
            id
            childImageSharp {
              gatsbyImageData
            }
          }
    }
        }
      }
    }
  }
`

const Future = ({data}) => {
    return (
      <Layout>
      <CurveHero pageTitle="Future" />
      <div className="overflow-x-hidden pt-18 bg-orange-100">
        <div className="timeline py-0">
          <div className="timeline__wrapper">
            <div className="timeline__single">
                {data.wpPage.timeline.timeline.map (({ timelineContent, timelineImage, timelineHeading }) => (
                  <div className="timeline-entry">
                    <div className="timeline-entry--dot" data-sal="flip-up" data-sal-delay="300" data-sal-duration="1200" data-sal-easing="ease-out-back"></div>
                    <div className="timeline-entry__right timeline__text timeline__col" data-sal="slide-right" data-sal-delay="300" data-sal-duration="1200" data-sal-easing="ease">
                    <h2>{timelineHeading}</h2>
                      <p className="timeline-entry__lbl">{timelineContent}</p>
                    </div>
                    <div className="timeline-entry__left timeline__img timeline__col" data-sal="slide-left" data-sal-delay="300" data-sal-duration="1500" data-sal-easing="ease">
                        <GatsbyImage image={getImage(timelineImage.localFile)} alt="A kitten" />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      </Layout>
    )

}
export const Head = () => <Seo title="Future"/>

export default Future
